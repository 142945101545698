.merchantRegisterBox{
    background-color: #fff;
    border:1px solid #ddd;
    border-radius: 10px;
    padding: 30px;
    .header{
      display: flex;
      justify-content: space-between;
      h4{
        border:2px dashed #666;
        padding: 8px 14px;
        border-radius: 6px;
        font-weight: 600;
        color:#666;
      }
    }
    .merchantRegisterForm{
      .ant-select-selector,.ant-input-affix-wrapper,.ant-form-item-control-input{
        background-color: @box-gray-color;
        border-radius: 6px;
        input,.ant-select-selection-placeholder,.ant-select-selection-item{
          background-color: @box-gray-color;
          font-size: .9rem;
        }
      }
      .mapBox{
        .ant-form-item-control-input{
          height: 180px;
          border: 1px solid #ddd;
          padding: 1rem;
          display: flex;
          align-items: flex-start;
          iframe{
            width: 100%;
            height: 100%;
          }
        }
      }
      .innerBox{
        background-color: #F9F9F9;
        border-radius: 6px;
        border:1px solid #e6e6e6;
        display: flex;
        align-items: center;   
        padding-right: 16px; 
        margin-bottom: 20px;
        img{
          margin-right: 5px;
          border-radius: 24px;
          padding: 16px;
          object-fit: cover;
        }
        h3{
            font-size: .95rem;
            margin-bottom: 0px;
        }
        p{
            margin-bottom: 0px;
            color: #868E9D;
            font-weight: 500;
            font-size: 0.8rem;
            span{
                color: #333;
            }
        }
        .stockSec{
            input{
                border-radius: 4px;
                background-color: #fff;
            }
            input::-webkit-input-placeholder {
                font-size: 0.8rem;
                color:#868E9D!important;
                font-weight: 500;
            }
              
            input:-ms-input-placeholder { 
                font-size: 0.8rem;
                color:#868E9D!important;
                font-weight: 500;
            }
            
            input::placeholder {
                font-size: 0.8rem;
                color:#868E9D!important;
                font-weight: 500;
            }
        }
      }
      .btnBox{
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
        .ant-btn{
            height: 40px;
            padding: 0 30px;
        }
      }
    }
}
.storeRegisterBox{
  .btnBox{
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    .ant-btn{
        height: 40px;
        padding: 0 30px;
        font-size: 1rem;
    }
  }
}