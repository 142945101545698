.campaignAddBox {
  .productLocationCard {
    .heading {
      font-weight: 600;
      margin-bottom: 15px;
      display: block;
      color: @sidebar-menu-dark;
    }
    .searchBox {
      .ant-input-prefix {
        span {
          color: #b3b8bb;
        }
      }
      input {
        font-size: 0.9rem !important;
      }
    }
    .productBox {
      background-color: @box-gray-color;
      padding: 0 15px 15px 15px;
      border-radius: @border-radius-box;
      .ant-table {
        background: transparent;
        table {
          thead {
            th {
              background-color: @box-gray-color !important;
              color: @sidebar-menu-dark;
              border: 0px;
              padding-bottom: 6px;
              padding-left: 5px;
              padding-right: 5px;
              &::before {
                background-color: transparent;
              }
              &.ant-table-cell-scrollbar{
                display: none;
              }
            }
          }
        }
        .ant-table-body {
          padding-right: 8px;
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar {
            width: 8px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #acacac;
          }
          table {
            border-collapse: separate;
            border-spacing: 0px 8px;
          }
          .ant-checkbox-checked .ant-checkbox-inner::after, .ant-checkbox-inner::after {
            position: absolute!important;
            display: block!important;
            border: 2px solid #fff !important;
            border-top: 0!important;
            border-left: 0!important;
            content: ' ' !important;
            font-size: 3px!Important;
            width: 5px!important;
            height: 10px!important;
            top: 43%!important;
            left: 28%!important;
          }
        }
        tbody {
          td {
            background-color: #fff;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            padding: 8px 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            img {
              height: 45px;
              width: 45px;
              object-fit: cover;
              border-radius: @border-radius-box;
            }
            .productNameValue {
              font-weight: 500;
              color: @sidebar-menu-dark;              
            }
            .upcText{
              font-size: .8rem;
              color:#acacac;
            }
            &:first-child {
              border-radius: 6px 0px 0px 6px;
              border-left: 1px solid #ddd;
            }
            &:last-child {
              border-radius: 0px 6px 6px 0px;
              border-right: 1px solid #ddd;
            }
          }
        }
      }
    }
    .locationBox {
      margin-bottom: 20px;
      & > .ant-checkbox-wrapper {
        display: flex;
        justify-content: right;
        margin-bottom: 20px;

      }
      .innerBox {
        border: 1px solid #ddd;
        border-radius: @border-radius-box;
        height: 370px;
        overflow-y: auto;
        .ant-checkbox-group {
          display: flex;
          flex-direction: column;
          .ant-checkbox-wrapper{
            border-bottom: 1px solid #ddd;
            padding: 10px;
            margin-left: 0px;
            &:last-child {
              border-bottom: 0px;
            }
          }
        }
      }
    }
  }
}
