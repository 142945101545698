@import url('../../../../assets/css/common.less');


.klogo{
    width: 200px;
}
header{
    &.is-sticky{
        .navbar{
            background: #fff;
            padding-top: 0.5rem!important;
            padding-bottom: 0.5rem!important;
            box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
            animation: slide 0.3s;
        }
    }
    @keyframes slide {
        0% {
          opacity: 0;
          transform: translateY(-50px);
        }
        100% {
          opacity: 1;
          transform: translateY(0%);
        }
      }
}
.header-inner {
    padding-bottom: 70px;
}

.navbar-innner {
    background-color: var(--yellow-color);
}
.header-navbar li.nav-item{
    padding-right: 3rem;
    .nav-link{
        color:#000!important;
    }
}
.header-navbar li.nav-item:last-child{
    padding-right: 0rem;
}
.header-navbar li.nav-item{
    &.active a::after{
        width: 30px!important;
        background: @f-secondary-color!important;
        height: 2px!important;
        bottom: 5px!important;
        left: 30%!important;
    }
}
.header-navbar li.nav-item a.nav-link{
    font-weight: 500;
    color:var(--black-color);
    position: relative;
    font-size: 0.95rem;
}
.header-navbar li.nav-item.active a.nav-link,.header-navbar li.nav-item a.nav-link:hover{
    color:var(--pink-color)!important;
}
.header-navbar li.nav-item.active a.nav-link:after {
    position: absolute;
    content: '';
    width: 45px;
    height: 2px;
    background: #fff89e;
    bottom: -5px;
    left: 0px;
}
.login-btn-section{
    .btn{
        background: #29C149!important;
        padding: 1rem 3rem!important;
        border-radius: 50px!important;
        color: #fff!important;
        font-size: 16px!important;
        &:focus{
            box-shadow: none!important;
        }
    }
}

.ant-popover-inner{
    padding:1rem;
    border-radius: 12px!important;
    .btn-close{
        position: absolute;
        top: 44px;
        right: 30px;
        background: #DBDEFA;
        border: 1px solid #2232E1;
        padding: 13px 14px;
        border-radius: 6px;
        color: #2232E1;
        .anticon{
            position: absolute;
            top: 6px;
            left: 7px;
        }
    }
    .ant-popover-title{
        border-bottom:0px;
        font-size: 2rem;
        padding-right: 20rem!important;
    }
    .ant-popover-inner-content{
        .ant-tabs{
            .ant-tabs-nav{
                &::before{
                    border-bottom:0px;
                }
                .ant-tabs-nav-wrap{
                    .ant-tabs-nav-list{
                        border: 1px solid #2232E1;
                        background-color:#DBDEFA;
                        border-radius: 25px;
                        .ant-tabs-tab{
                            border-radius: 25px;
                            background: #DBDEFA;
                            border: 0px;
                            font-size: .8rem;
                            &.ant-tabs-tab-active{
                                background-color:#2232E1;
                                border-radius: 25px;
                                color:#fff;
                                .ant-tabs-tab-btn{
                                    color:#fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.login-form{
    .ant-input-affix-wrapper{
        padding: 11px 11px;
        border-radius: 25px;
        border-color:#DBDEFA!important;
        .ant-input-suffix{
            padding-right: 10px;
            color: #DBDEFA;
        }
    }
    .btn-section{
        .ant-btn{
            background-color: #FF8911!important;
            border-color:#FF8911!important;
            border-radius: 50px;
            padding: 1.3rem 2.2rem;
            line-height: 0rem;
        }
        .ant-form-item-control-input-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
@hack: true;@import "/var/www/html/new_brand/src/theme/light-theme.less";