/*Media Query*/
@media only screen and (max-width: 991px)  {
    .m-d-block{
        display: block!important;
    }
    .m-p-0{
        padding: 0px;
    }
    .m-m-0{
        margin: 0px!important;
    }
    .m-ml-0{
        margin-left: 0px;
    }
    .ant-breadcrumb{
        padding:0 14px!important;
    }
    .left-heading-right-button{
        display: block;
     }
     .filter-sec {
         display: block!important;
        .ant-space-item{
            &:last-child{
                display: block;
                margin-right:0px!important;
                padding: 0px 14px;
                margin-bottom: 14px!important;
            }
        }
        .search-input{
            width: 90%!important;
            margin-left: 14px!important;
        }
        
     }
     #mapBox{
        height: 200px!important;
    }
     .grid-list-section .ant-tabs-nav .ant-tabs-tab{
        margin-top: 2px!important;
    }
    .filterBox{
        .ant-col{
            margin-bottom: 5px;
            margin-left: initial;
            display: block!important;
            width: 100%;
            &:nth-of-type(1){
                padding-left: 6px!important;
            }
        }
    }
    .content-body{
        .ant-row{
            .ant-col {
                .bg-white{
                    margin: 0px 14px 14px 14px!important;
                    padding: 14px!important;
                }
            }
        }
    } 
    .p-table{
        .ant-table-content{
            overflow: auto hidden;
        }
    }
    #Dashboard .dashboard-left-box .card-header{        
        padding: 14px 14px 113px 14px!important;
    }
    #Dashboard .store-information-box .card-header h2{
        line-height: inherit;
    }
    #Dashboard .store-information-box .card-header .stock-box{
        align-items: flex-start;
    }
    #Dashboard .store-information-box .d-flex.justify-content-between{
        display: block!important;
    }
    #KCampaigns .card-device .ant-card-body {
        padding: 10px;
    }
    #KCampaigns .card-device .card-header .header-top {
        width: 62%;
    }
    #KCampaigns .card-device .card-header{
        padding: 0.5rem 0.5rem;
    }
    #KCampaigns .campaign-details-box .ant-card-body{
        padding: 0px
    }   
    #KCampaigns .campaign-details-box .card-details .card-symbol{
        justify-content: center;
    }
    #KCampaigns .campaign-details-box .card-details .card-info .d-flex.mb-2{
        flex-direction: column;
    }
    #KCampaigns .campaign-details-box .card-details .card-info .d-flex.mb-2 span.fs-12,#KCampaigns .campaign-details-box .card-details .card-info .d-flex.mb-2 span.badge{
        display: block;
        margin: auto;
    }
    #KCampaigns .campaign-details-box .card-details .card-info .ant-statistic{
        margin-bottom: 10px!important;
        text-align: center!important;
    }
    #KCampaigns .card-device .card-header .card-toolbar{
        align-items: flex-start;
    }
    #KCampaigns .card-device .ant-statistic{
        margin-bottom: 10px!important;
        text-align: center!important;
    }
    #KCampaigns .campaign-details-box .card-details .count-box{
        justify-content: center;
    }
    #KCampaigns .preview-template .top,#KCampaigns .preview-template .products-grid-box,#KCampaigns .preview-template .bottom{
        width: 100%!important;
    }
    #KCampaigns .preview-template .top{
        height: 100px;
    }
    #KCampaigns .preview-template .top h4 {
        margin-left: 13px;
        margin-top: -3px;
        font-size: 8px;
    }
    #KCampaigns .preview-template .top .date {
        right: 25px;
        top: 33px;
        font-size: 6px;
    }
    #KCampaigns .preview-template .top .date h5 {
        margin-bottom: 0px;
        font-size: 8px;
        font-weight: 700;
    }
    #KCampaigns .preview-template .products-grid-box .products-grid .short-name {
        font-size: 1.25rem;
    }
    #KCampaigns .preview-template .bottom{
        height: 63px;
    }
    #KCampaigns .preview-template .bottom h4 {
        left: 60px;
        top: 25px;
        font-size: 7px;
    }
    #KCampaigns .preview-image-box{
        position:relative;
        top: 0;
        right: 0;
        margin-bottom: 10px;
        text-align: center;
    }
    #KCampaigns .preview-template .bottom p {
        left: 4px;
        top: 39px;
        font-size: 6px;
    }
}
@media only screen and (max-width: 991px) and (orientation:landscape) {
    .left-heading-right-button{
        display: flex;
     }
    .filterBox{
        .ant-col{
            width: auto;
            margin-right: 5px!important;
            &:nth-of-type(1){
                padding-left: 0px!important;
            }
        }
    }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
    .left-heading-right-button{
        display: flex;
     }
    .filterBox{
        .ant-col{
            width: auto;
            &:nth-of-type(1){
                padding-left: 0px!important;
            }
        }
    }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape){
    .filterBox{
        .ant-col{
            width: auto;
            &:nth-of-type(1){
                padding-left: 6px!important;
            }
        }
    }
}