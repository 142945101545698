.campaingInventory{
    background-color: #fff;
    border-radius: 10px;
    border:1px solid #ddd;
    padding: 30px 40px;
    .ant-row:first-child{
        min-height: 200px;
        max-height: 500px;
        overflow-y: auto;
        margin-bottom: 0px;
    }     
    .innerBox{
        background-color: #F9F9F9;
        border-radius: 10px;
        border:1px solid #e6e6e6;
        display: flex;
        align-items: center;   
        padding-right: 25px; 
        img{
            margin-right: 20px;
            border-radius: 10px;
            padding: 15px 15px 15px 0px;
            object-fit: cover;
        }
        h3{
            font-size: 1rem;
            margin-bottom: 0px;
        }
        p{
            margin-bottom: 0px;
            color: #868E9D;
            font-weight: 500;
            font-size: 0.8rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span{
                color: #333;
            }
        }
          .stockSec{
            input{
                border-radius: 6px;
            }
            input::-webkit-input-placeholder {
                font-size: 0.8rem;
                color:#333!important;
                font-weight: 500;
            }
              
            input:-ms-input-placeholder { 
                font-size: 0.8rem;
                color:#333!important;
                font-weight: 500;
            }
            
            input::placeholder {
                font-size: 0.8rem;
                color:#333!important;
                font-weight: 500;
            }
          }
        
    }
    .btnJoinCampaign{
        background-color: @ekikart-primary-color!important;
        border-color: @ekikart-primary-color!important;
        height: 42px;
        padding: 0px 30px;
        font-size: 1rem;
        margin-right: 23px;
    }
} 

.joinLocationBox{
    background-color: #fff;
    border-radius: 10px;
    border:1px solid #ddd;
    .stepBox{
      display: flex;
      flex-direction: row;  
      width: 100%;
      .stepTitle{
        width: 25%;            
        padding: 30px 25px;
        display: flex;
        align-items: center;
        .ant-steps{
          .ant-steps-item{
            &.ant-steps-item-active{
              .ant-steps-item-icon{
                background-color: @ekikart-primary-color;
                border:1px solid @ekikart-primary-color; 
                .ant-steps-icon{
                  color:#fff!important;
                }               
              }
            }
            &.ant-steps-item-finish{
              .ant-steps-item-icon{
                background-color: @text-warning;
                border:1px solid @text-warning!important; 
                .ant-steps-icon{
                  color:#fff!important;
                }               
              }
            }
            .ant-steps-item-container{
              padding-bottom: 60px;
              .ant-steps-item-tail{
                left: 18px;
                top: 2px;
                &::after{
                  background-color: #333!important;
                }
              }
              .ant-steps-item-icon{
                width: 36px;
                height: 36px;
                border-radius: 4px;
                border:1px solid #333;
                .ant-steps-icon{
                  font-weight: 700!important;
                  color:#333;
                }
              }
              .ant-steps-item-title{
                color:#333;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .stepsContent{
      width: 75%; 
      border-left:1px solid #ddd;
      .ant-select-arrow{
          color: #333;
      }
     .ant-select-selector,.ant-input-affix-wrapper{
        background-color: #F9F9F9;
        border-radius: 6px;
        height: 48px;
        padding: 3px 12px;
        input,.ant-select-selection-placeholder,.ant-input-disabled, .ant-input[disabled]{
          background-color: #F9F9F9;
          font-size: .9rem;
          color: #666;
          &::-webkit-input-placeholder { 
            color: #666;;
          }
          
          &:-ms-input-placeholder { 
            color: #666;
          }
          
          &::placeholder {
            color: #666;
          }
        }
      }
      iframe{
        background-color: #F9F9F9;
        border-radius: 6px;
        padding: 20px;
        border: 1px solid #ddd;
      }
      .ant-btn{
        height: 40px;
        padding: 0 30px;
      }
      .ant-btn.ant-btn-primary{
          height: 40px;
          padding: 0 30px;
          background-color: @ekikart-primary-color!important;
          border-color:@ekikart-primary-color!important;
      }
      .storeInformationBox{
          padding: 30px 40px;
          .ant-col:last-child{
            .innerBox{
              margin-bottom: 0px;
            }            
          }
          .innerBox{
            padding: 20px;
            border: 1px solid #ddd;
            border-radius: 14px;
            margin-bottom: 30px;
            h1{
              margin-bottom: 1rem;
              text-align: left;
              font-size: 1.6rem;
            }
          }
          .mapBox > div > div > div > div {
            height: 348px!important;
          }
          .fillBox{
            background-color: #F9F9F9;
            padding: 30px!important;
            border: 1px solid #ddd;
            border-radius: 6px;
            height: 386px;
            p{
              margin:15px 0;
            }
            ul{
              padding-left: 16px;
              li{
                font-size: 0.8rem;
              }
            }
          }
      }
      .addInventoryBox{
        padding: 30px 40px;
        .innerBox{
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 14px;
          h1{
            margin-bottom: 1rem;
            text-align: left;
            font-size: 1.2rem;
          }
          .campaingInventory{
              padding: 0px;
              border:0px;
          }
          img{
              margin-right: 10px!important;
              border-radius: 14px!important;
              padding: 0px!important;
              object-fit: contain!important;
          }
          .contentBox{
            width: calc(100% - 80px);
            padding-right: 10px;
            .stockSec {
              padding: 0 0px 0 0px!important;
            }
          }
        }
      }
      .verifyAccountBox{
          padding: 140px 40px 40px 40px;          
          .innerBox{
            padding: 30px 40px!important;
            border: 1px solid #ddd;
            border-radius: 14px;
            margin-bottom: 30px;
            h1{
              font-size: 1.6rem;
              margin-bottom: 1rem;
            }
            .bankIcon{
                background: #fff;
                padding: 1px;
                border-radius: 6px;
                border: 1px solid #ddd;
            }
            .ant-input-affix-wrapper-disabled{
              .ant-input-disabled{
                height: 40px!important;
              }
            }
            .ant-input-disabled, .ant-input[disabled]{
                background-color: #F9F9F9;
                font-size: .9rem;
                color: #666;
                height: 48px;
                padding: 3px 12px;
            }
          }
      }
      .verifiedAccountBox{
          padding: 140px 40px 40px 40px; 
          .innerBox{
            padding: 40px 40px!important;
            background-color: #F9F9F9;         
            border: 1px solid #ddd;
            border-radius: 14px;
            margin-bottom: 30px;
            text-align: center;
            .anticon {
              font-size: 4rem;              
              margin-bottom: 1rem;
              color:@ekikart-primary-color;
            }
            h1{
              font-size: 2rem;
              margin-bottom: 0px;
            }
            p{
              margin-bottom: 1.6rem;
            }
          }
      }
      .stepsAction{
        padding: 0 40px 30px 40px;
        text-align: right;
      }
    }
}