.campaingView {
  .left {
    .campaignHeading {
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #ddd;
      display: flex;
      align-items: center;
      padding: 20px;
      margin-bottom: 20px;
      img {
        margin-right: 10px;
        border-radius: 6px;
        object-fit: contain;
      }
      h3 {
        font-size: 1.2rem;
        margin-bottom: 0px;
      }
      p {
        margin-bottom: 0px;
        color: #868e9d;
        font-size: 0.85rem;
      }
      .btn-join {
        background-color: @ekikart-primary-color!important;
        border-color: @ekikart-primary-color!important;
        height: 35px;
        padding: 0px 20px;
        font-weight: 500;
        font-size: .8rem;
        margin-bottom: 6px;
      }
    }
    .products {
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #ddd;
      padding: 20px;
      h2{
        font-size: 1.2rem;
      }
      .ant-row {
        min-height: 240px;
        height: 350px;
        overflow-y: auto;
        h2 {
          color: #333333;
        }
        .productLists {
          border-radius: 10px;
          border: 1px solid #ddd;
          overflow: hidden;
          img {
            width: 100%;
            height: 150px;
            border-radius: 10px;
            object-fit: contain; 
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            &:hover{
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            } 
          }
          h4 {
            padding: 20px 30px;
            margin-bottom: 0px;
            text-align: center;
            font-size: 1.1rem;
            color: #333333;
          }
          .pName{
            padding: 8px 30px!important;
          }
          .upcName{
            padding: 0px 30px!important;
            text-align: center;
            color: #333333;
          }
        }
      }
    }
  }
  .right {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 20px;
    .offSec {
      padding: 45px  66px  45px 30px;
      background-color: #e24a71;
      border-radius: 10px;
      border-left: 4px dashed #fff;
      color: #fff;
      position: relative;
      margin-bottom: 35px;
      h1 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 0px;
        color: #fff;
      }
      .blank {
        background: #ffda60;
        position: absolute;
        right: 0px;
        width: 20%;
        height: 100%;
        top: 0px;
        border-left: 4px dashed;
        border-right: 4px dashed;
        border-radius: 0px 10px 10px 0;
      }
    }
    .noOfVoucher {
      background-color: #5f8aaa;
      padding: 45px 30px;
      border-radius: 10px;
      color: #fff;
      margin-bottom: 35px;
      line-height: 35px;
      h4 {
        font-size: 1.2rem;
        margin-bottom: 0px;
        color: #fff;
      }
      h1 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 0px;
        color: #fff;
      }
    }
    .totalOfferValue {
      background-color: #05d7a0;
      padding: 45px 30px;
      border-radius: 10px;
      color: #fff;
      line-height: 35px;
      h4 {
        font-size: 1.2rem;
        margin-bottom: 0px;
        color: #fff;
      }
      h1 {
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 0px;
        color: #fff;
      }
    }
  }
}
