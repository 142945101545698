.otpSec{
    background-color: #fff;
    border:1px solid #ddd;
    border-radius: 10px;
    padding: 50px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .header{
      display: flex;
      flex-direction: column;
      h4{
        border:2px dashed #666;
        padding: 8px 14px;
        border-radius: 6px;
        font-weight: 600;
        color:#666;
      }
      p{
        color:#666;
      }
    }
    .otpBox{
        input{
            border: 1px solid #ddd;
            font-size: .9rem;
            width: 3.5em!important;
            text-align: center;
            height: 3rem;
            border-radius: 6px;
            margin-right: 20px;
        }   
    }
    .btnBox{
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
        .ant-btn{
            height: 40px;
            padding: 0 30px;
        }
        p{
            color:#666;
        }
    }
}