@import '~antd/dist/antd.less';
@import './common.less';
@import './media.less'; 
@import './button.less';

/*Font*/
body{
  font-family: 'Poppins', sans-serif!important;
}
#sidebar-menu > ul > li > a,#sidebar-menu ul ul a{
  font-family: "Sarabun", sans-serif!important;
}
/*Header*/
.headerFix{
  position: fixed;
  z-index: 1000;
  width: calc(100% - 285px);
}
.content-body{
  padding-top: 100px;
}

/*Layout*/
.ant-layout.site-layout{
  padding-left: 286px;
}

/*Filter*/
.filter-sec{
  background-color: #fff;
  box-shadow: 0 2px 6px -6px #666;
  margin-bottom: 25px;
  padding: 0 25px;
  height: 68px;
  .ant-space-item{
    &:last-child{
      margin-left: auto;
      margin-right: 14px;
      margin-top: 3px;
    }
  }
  .btn-primary{
    background-color: @primary-color;
    color:#fff;
    box-shadow: 0 2px #0000000b;
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border: 1px solid transparent;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 4px; 
    &:hover{
      color: #fff;
      background: @primary-color-hover;
      border-color: @primary-color-hover;
    } 
  }
}
.single-filter-sec{
  background-color: #fff;
  box-shadow: 0 2px 6px -6px #666;
  margin-bottom: 25px;
  padding: 0 25px;
  height: 68px;
}
.inner-filter-sec{
  .ant-space-item{
    &:last-child{
      margin-left: inherit!important;
      margin-right: 14px;
      margin-top: 0px!important;
    }
    &:nth-last-child(2){
      margin-left: auto!important;
    }
}
}

/*Search*/
.search-input{
  /*border: 0px!important;*/
  padding: 8px 11px!important;
  width: 330px!important;
  .ant-input-prefix, .ant-input-suffix{
    color:@heading-light-color;
  }
}

/*Filter Dropdown*/
.locationSelect{
  .ant-select-selection-overflow-item:nth-of-type(1){
    width: 60%!important;
  }
}
.locationDropdown {
  span{
    color:@primary-color
  }
}

/*Empty*/
.emptyBox{
  .ant-row{
    align-content: center;
    height: 100%;
  }
  .emptyMessageBox{
    background-color: #fff;
    border-radius: 14px;
      p{
        color:@text-color!important
      }
  }
}


/*Grid & List View*/
.grid-list-section{
  .ant-tabs-nav{
    position: absolute!important;
    top: -50px;
    right: 13px;
    .ant-tabs-tab{
      background-color: #fff;
      padding: 2px 8px;
      margin-left: 10px;
      font-size: 1.2rem;
      border-radius: 5px;
      &.ant-tabs-tab-active{
        background-color: @primary-color;
        span{
          color:#fff
        }
      }
      .anticon {
        margin-right:0px;
      }
    }
    .ant-tabs-ink-bar{
      background:transparent
    }
  }
}

/*Table*/
.p-table-panel{
  background-color: #fff;
  padding: 14px;
  border-radius: 4px;
}
.p-table{
  background-color: white;
  table{
    tr{
      &:hover td{
        background-color: @bg-row-highlightcolor!important;
        .fw-600{
          color:@primary-color
        }
        .badge.linked{
          background-color: @primary-color;
          color:#fff;
        }
        .badge.unlinked{
          background-color: @error-color;
          color:#fff;
        }
        .ant-btn-primary{
          background-color: @primary-color;
          color: #fff;
        } 
        
      }
      th{
        .p-x8-y16;
        /*background-color: #fff;
        &:first-child{
          padding-left: 0px;
        }
        &:last-child{
          padding-right: 0px;
        }*/
        .ant-table-column-sorters,.ant-table-column-has-sorters{
          padding: 0px!important;
        }
      }
      &:last-child td{
        .br-b-0()
      }
      td{        
        .p-x6-y16;
        .badge{
          .badge-sm;
          text-transform: capitalize;        
          &.linked{
            background-color:@bg-color-ligher-gray;
            color:@primary-color;
          }
          &.unlinked{
            background-color:@bg-danger;
            color:@text-danger;
          }
         
          &.initiated{
            background-color:@bg-info;
            color:@text-info;
          }
          &.started{
            background-color:@bg-success;
            color:@text-success;
          }
          &.ended{
            background-color:@bg-danger;
            color:@text-danger;
          }
        
        }
        span.badge-sm{
          background-color:#eee;
          color:#000;
        }
        img{
          padding: 8px;
          background-color: @bg-color-light-gray;
          border-radius: 0.475rem;
        }
        &.pl-0{
          padding-left: 0px!important;
        }
        &.pr-0{
          padding-right: 0px!important;
        }
        .ant-btn-primary{
          background-color: @bg-color-ligher-gray;
          color: @primary-color;
          border-color:@bg-color-ligher-gray;
          height: 26px;
          border-radius: 6px;
          box-shadow: none;
          .fs-08;
        } 
        .short-name{
          background-color: @primary-color;
          padding: 8px 11px;
          border-radius: 50%;
          font-weight: 600;
          color:#fff;
          display: inline-block;
        }
        span{
          &:last-child{
            span{
              display: none;
            }
          }
        }
      }
    }
  }
}


/*Status Lable*/
.status-label{
  padding: 4px 8px;
  border-radius: 6px;
  box-shadow: none;
  font-size: 0.8rem;
  text-transform: capitalize;
  width: 80px;
  display: block;
  &.status-approved{
    background-color: @bg-color-ligher-gray;
    color: @primary-color;
    border-color: @bg-color-ligher-gray;  
  }
  &.status-rejected{
    background-color: @bg-danger;
    color: @text-danger;
    border-color: @bg-danger;  
  }
  &.status-pending{
    background-color: @bg-warning;
    color: @text-warning;
    border-color: @bg-warning;  
  }
}

/*Badge*/
.badge{
  text-transform:capitalize;
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 6px;
  &.linked{
    background-color:@bg-color-ligher-gray;
    color:@primary-color;
  }
  &.unlinked{
    background-color:@bg-danger;
    color:@text-danger;
  }
  &.initiated{
    background-color:@text-info;
    color:#fff;
  }
  &.started{
    background-color:@text-success;
    color:#fff;
  }
  &.ended{
    background-color:@text-danger;
    color:#fff;
  }
}

/*Timeline*/
.ant-timeline-item{
  padding-bottom: 19px!important;
}
.ant-timeline-item-last{
    padding-bottom: 0px!important;
    .ant-timeline-item-content{
      min-height: 0px!important;
      padding-bottom: 1px!important;
   }
}

.notificationBox{
  width: 350px;
  
  header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 4px 0 12px 0;
    h3{
      font-size: 1.3rem;
    }
    p{
      a{
        font-size: 0.8rem;
      }
    }
  }
  main{
    min-height: 75px;
    max-height: 330px;
    margin: 20px 0 0 0;
    overflow-y: auto;
    .notificationData{
      display: flex;
      margin-bottom: 15px;
      border-radius: 6px;
      margin-right: 5px;
      padding: 10px;
      border: 1px solid #ddd;
      cursor: pointer;
      &:hover{
        background-color: @bg-warning;
        border:1px solid @orange-color;
        .icon{
          border-color:#fff;
        }
      }
      &:last-child{
        margin-bottom: 10px;
      }
      .icon{
        border: 2px solid #ddd;
        border-radius: 10px;
        margin-right: 10px;
        width: 50px;
        height: 44px;
        padding: 1px;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .notificationContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title{
          font-weight: 500;
          line-height: 1.1rem;
          font-size: 0.8rem;
        }
        .date{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color:#ABABAB;
        }
      }
    }
  }
  footer{
    padding: 15px 0 10px 0;
    p{
      text-align: center;
    }
  }
}


.colorPicker{
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  p{
    font-weight: 500;
  }
  .p-form-control{
    width: 89%;
    padding: 8px 11px;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all .3s;
  }
  .c-form-control{
    height: 100%;
  }
}
@hack: true;@import "/var/www/html/new_brand/src/theme/light-theme.less";