.campaignDetailSec{
    .campaignHeader{
        border-bottom: 2px solid #ddd;
        position: relative;
        .divider{
            height: 2px;
            width: 30%;
            position: absolute;
            bottom: -1px;
        }
        h1{
            font-size: 1.6rem;
            margin-bottom: 0px;
        }
        .joinBtn{
            padding: 10px 26px;
            height: 36px;
            line-height: 36px;
            font-size: 1rem;
        }
    }
    .countBox{
        border-left: 15px solid;
        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-radius: 14px;
        text-align: center;
        margin-bottom: 20px;
        padding: 20px;
        h1{
            font-size: 1.8rem;
            margin-bottom: 0px;
            font-weight: 900;
        }
        p{
            margin-bottom: 0px;
            font-size: 1rem;
            color:#B4B6BB;
            font-weight: 500;
        }
    }
    .dateBox{
        border: 1px solid #ddd;
        border-radius: 14px 0 0 14px;
        padding: 25px;
        display: flex;
        .startIcon{
            background-color: #F0EFFF;
            padding: 5px 15px;
            border-radius: 6px;
            font-size: 2rem;
            margin-right: 30px;
        }
        .startdata{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        &.endDateBox{
            border-radius: 0 14px 14px 0;
            border-left: 0px;
        }
    }
    .statesBox{
        .ant-card{
            border-color:#ddd;
            border-radius: 14px;
        }
        .ant-card-head{
            border-bottom-color: #ddd;
            .ant-card-head-title{
                font-size: 1.2rem;
                padding: 12px 0px;
                font-weight: 600;
            }
        } 
        .ant-card-body{
            height: 188px;
            overflow-y: auto;
              &::-webkit-scrollbar {
                width: 8px;
                background-color: #f5f5f5;
              }
               
              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: #f5f5f5;
              }
               
              &::-webkit-scrollbar-thumb {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #acacac;
              }
              
            p{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0px;
                span{
                    background-color: #F6F6F6;
                    padding: 4px 16px;
                    border-radius: 30px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    color:#000;
                    font-size: .8rem;
                }
            }
        }  
    }
}