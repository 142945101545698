.successSec{
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;  
    margin-bottom: 20px;
    text-align: center;
    h1{
        font-size: 2rem;
        margin-bottom: 0px;
    }
    p{
        margin-bottom: 30px;
        color: #868E9D;
    }
    .checkmark {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: block;
        stroke-width: 2;
        stroke: #4bb71b;
        stroke-miterlimit: 10;
        box-shadow: inset 0px 0px 0px #4bb71b;
        animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
        position:relative;
        top: 5px;
        right: 5px;
        margin: 0 auto;
        padding: 0px 0!important;
        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 2;
            stroke-miterlimit: 10;
            stroke: #4bb71b;
            fill: #fff;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
        }
        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
        }
    }   
    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }    
    @keyframes scale {
        0%, 100% {
            transform: none;
        }
    
        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
    @keyframes fill {
        100% {
            box-shadow: inset 0px 0px 0px 30px #4bb71b;
        }
    }
    .btnDone{
        background-color: @ekikart-primary-color!important;
        border-color: @ekikart-primary-color!important;
        height: 44px;
        padding: 0 80px;
        font-weight: 500;
    }
}