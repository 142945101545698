p{
  font-size: 1rem;
}
.banner-section {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
  background: linear-gradient(#dbdefa, #fff);
  h1 {
    padding-top: 6rem !important;
    font-size: 3.4rem;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 5rem;
    color: #001847;
    margin-bottom: 2rem!important;
  }
  .btn-app-store,
  .btn-android-store {
    padding: 1rem 2rem;
    border-radius: 50px;
    color: #fff;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    line-height: 2rem;
    font-weight: 500;
    text-decoration: none;
    .anticon {
      font-size: 2rem;
    }
    &.bg-fill {
      background-color: @f-blue-color;
      color: #fff;
    }
    &.bg-unfill {
      border: 1px solid @f-blue-color;
      color: @f-blue-color;
      &:hover {
        background-color: @f-blue-color;
        color: #fff;
      }
    }
  }
  h5 {
    font-weight: 600;
    font-size: 1.1rem;
  }
  .h-trust-icon {
    position: absolute;
    left: 242px;
    width: 200px;
    bottom: 41px;
  }
  .h-voucher-icon {
    position: absolute;
    left: -22px;
    width: 212px;
    bottom: 218px;
  }
}
.free-section {
  h2 {
    line-height: 3.6rem;
    font-size: 2.6rem;
    font-weight: 600;
    margin-bottom: 2rem !important;
  }
  .inner-box {
    border-radius: 14px;
    .left {
      p {
        line-height: 1.6rem;
      }
    }
    .right {
      .step {
        p {
          font-size: 1.2rem;
        }
      }
      .borderLR {
        padding: 0px 4rem;
        border-left: 1px solid #000;
        border-right: 1px solid #000;
      }
    }
  }
}
.about-section {
  h2 {
    line-height: 3.6rem;
    font-size: 2.6rem;
    font-weight: 600;
    margin-bottom: 2rem !important;
  }
}
.offer-section {
  .container {
    background-image: url("../../assets/images/Frontend/background with circle.png01.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 14px;
    padding: 6rem 5rem;
    h2 {
      line-height: 3.6rem;
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 2rem !important;
    }
    .nav {
      position: relative;
      padding-right: 5rem;
      &::before {
        position: absolute;
        content: "";
        width: 2px;
        height: 80%;
        right: -2px;
        top: 32px;
        background: #ddd;
      }
      .nav-link {
        background-color: #ffe9f8 !important;
        border: 1px solid #fff;
        margin-bottom: 2rem;
        color: #000;
        padding: 0.6rem 5rem 0.6rem 1rem;
        font-size: 1.1rem;
        &::before {
          position: absolute;
          content: "";
          width: 25px;
          height: 25px;
          border-radius: 50%;
          right: -13px;
          background: #fff;
          border: 5px solid #ddd;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        img {
          padding: 0.6rem;
          background-color: @f-primary-color;
          border-radius: 4px;
        }
        &.active {
          background-color: #fff !important;
          img {
            background-color: @f-secondary-color;
          }
          &::before {
            position: absolute;
            content: "";
            width: 25px;
            height: 25px;
            border-radius: 50%;
            right: -13px;
            background: #fff;
            border: 4px solid @f-secondary-color;
          }
        }
      }
    }
    .tab-content {
      padding-left: 15rem;
      margin-top: -6rem;
    }
  }
}
.features-section {
  position: relative;
  .boll-orange-section {
    position: absolute;
    left: -33px;
    top: 0px;
    img {
      width: 150px;
    }
  }
  .boll-green-section {
    position: absolute;
    right: -34px;
    top: -20px;
    img {
      width: 150px;
    }
  }
  h2 {
    line-height: 3.6rem;
    font-size: 2.6rem;
    font-weight: 600;
    margin-bottom: 2rem !important;
  }
  .location-icon {
    position: absolute;
    left: 270px;
    width: 300px;
    top: 74px;
  }
}
.download-section {
  .container {
    background-image: url("../../assets/images/Frontend/background.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 14px;
    padding: 0rem 5rem;
    .left {
      .heading {
        padding-top: 10rem;
        color: @f-secondary-color;
        font-size: 1.4rem;
      }
      h2 {
        line-height: 3.6rem;
        font-size: 2.6rem;
        font-weight: 600;
        margin-bottom: 2rem !important;
      }
      .input-group > .form-control,
      .input-group > .form-control:focus,
      .input-group > .form-control:active {
        padding: 1rem 2rem;
        border-radius: 50px 0 0 50px;
        border: 0px;
        box-shadow: none;
      }
      .input-group .input-group-text {
        border-radius: 25px;
        padding: 0px;
        display: flex;
        margin-left: -20px;
        border: 0px;
        z-index: 10;
        button {
          display: flex;
          height: 100%;
          line-height: 2.4rem;
          border-radius: 50px;
          border: 0px;
          padding: 0.4rem 1.42rem;
        }
      }
    }
  }
}
