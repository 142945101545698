/*Products List*/
.productsBox {
    .ant-empty{
      height: 300px!important;
    }
    .heading {
      color: @sidebar-menu-dark;
      margin-bottom: 20px;
    }
    .innerBox {
      border-radius: 4px;
      margin-bottom: 3rem;
      overflow: hidden;
      .shadow-sm;
      img {
        width: 100%;
        height: 150px;
        object-fit: contain;
        border-radius: 4px 4px 0 0;
        margin-bottom: 0px;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;  
        &:hover{
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }          
      }
     
      .details {
        padding: 10px;
        color: @sidebar-menu-dark;
        h4{
            font-size: 1rem;
            margin-bottom: 0px;
            font-weight: 600;
        }
        p {
          margin-bottom: 0px;
          font-weight: 600;
          strong{
            color: #666;
          }
          span {
            color: #B4B6BB;
            font-size: 0.85rem;
          }
        }
      }
    }
  }