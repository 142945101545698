#Public{
    @import "../../assets/css/common.less";
    @import '_list';
    @import '_detail';
    @import '_productList';
    @import '_add.less';
    @import '_otp.less';
    margin: 10px;
    background-color: #fff;
    header{
        display: flex;
        justify-content: center;
        height: 130px!important;
        text-align: center;
        margin: 0 0 5px 0px;
        box-shadow: 0px 6px 5px -6px rgba(207,207,207,.75);
        -webkit-box-shadow: 0px 6px 5px -6px rgba(207,207,207,0.75);
        -moz-box-shadow: 0px 6px 5px -6px rgba(207,207,207,0.75);
        img{
            padding: 20px;
            object-fit: contain;
            width: 100%;
        }
    }
    footer{
        background-color: #000;
        text-align: center;
        color: #fff;
        padding: 16px;
    }
}
@hack: true;@import "/var/www/html/new_brand/src/theme/light-theme.less";