.drawerForm {
    .ant-drawer-content-wrapper {
      width: 100% !important;
      .ant-drawer-body {
        padding: 10px;
        overflow: hidden;
      }
      .ant-upload-list-picture-card {
        background-color: #fafbfd;
        font-weight: 600;
        margin-bottom: 8px;
        border-radius: 12px;
        .ant-upload-list-picture-card-container {
          margin-bottom: 0px;
          height: 84px;
          .ant-upload-list-item-done{
            border:2px dashed #d9d9d9!important;
            border-radius: 14px;
          }
        }
        
        .ant-upload.ant-upload-select-picture-card {
          border: 2px dashed rgb(217, 217, 217);
          margin-bottom: 0px;
          border-radius: 12px;
          .anticon-cloud-upload {
            color: @orange-color;
            font-size: 1.8rem;
          }
        }
        .ant-upload.ant-upload-select-picture-card > .ant-upload {
          color: rgb(196, 189, 189);
        }
        .ant-upload.ant-upload-select-picture-card,
        .ant-upload-list-picture-card-container {
          width: 100% !important;
        }
      }
      .ant-form-item {
        margin-bottom: 8px !important;
        .ant-form-item-label {
          padding: 0 0 4px;
          label {
            color: #afafaf !important;
            font-weight: 500;
          }
        }
        .ant-input {
          padding: 8px 11px !important;
          background: #fafbfd;
          font-weight: 600;
          color: #777;
        }
      }
      .ant-drawer-header-close-only{
        padding: 0px!important;
        .ant-drawer-close{
          margin-right: 17px!important;
          padding-right: calc(20px - 17px)!important;
        }
      }
      .ant-drawer-header-title,.ant-drawer-header-no-title{
          .anticon-close{
              background: @orange-color;
              color: #fff;
              padding: 6px;
              border-radius: 50%;
              position: absolute;
              top: 4px;
              right: -11px;
              font-size: .85rem;
          }
      }
    }
  }