.productAddCard {
  border-radius: 16px;
  &.selectedProduct {
    position: relative;
    .anticon-delete {
      position: absolute;
      top: 20px;
      right: 20px;
      background: #fff;
      padding: 8px 8px;
      color: #000;
      border-radius: 50%;
      font-size: 1rem;
    }
    .selectedImage {
      position: relative;
      overflow: hidden;
      border-radius: 14px;
      img {
        border-radius: 14px;
        width: 100%;
        height: 136px;
        object-fit: cover;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
      }
      &:hover img {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
      }
    }
    .ant-form-item {
      .ant-form-item-label {
        label{
          height: 23px;
        }
      }
    }
  }

  .ant-card-body {
    padding: 14px;
    .ant-upload-list-picture-card {
      background-color: #fafbfd;
      font-weight: 600;
      margin-bottom: 8px;
      border-radius: 12px;
      .ant-upload-list-picture-card-container {
        margin-bottom: 0px;
      }
      .ant-upload.ant-upload-select-picture-card {
        border: 2px dashed rgb(217, 217, 217);
        margin-bottom: 0px;
        border-radius: 12px;
        .anticon-cloud-upload {
          color: @orange-color;
          font-size: 1.8rem;
        }
      }
      .ant-upload.ant-upload-select-picture-card > .ant-upload {
        color: rgb(196, 189, 189);
      }
      .ant-upload.ant-upload-select-picture-card,
      .ant-upload-list-picture-card-container {
        width: 100% !important;
      }
    }
    .ant-form-item {
      margin-bottom: 8px !important;
      .ant-form-item-label {
        padding: 0 0 4px;
        label {
          color: #afafaf !important;
          font-weight: 500;
        }
      }
      .ant-input {
        padding: 8px 11px !important;
        background: #fafbfd;
        font-weight: 600;
        color: #777;
      }
    }
  }
  .btnAddProduct {
    background: @orange-color;
    color: #fff;
    height: 321px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    &.disabled{
      cursor: default;
      background-color: @menu-text-light!important;
      border-color: @menu-text-light!important;
      &:hover{
        border-color: @menu-text-light!important;
      }
    }
    &:hover{
      background-color: @orange-color;
      border-color: @orange-color;
    }
    .anticon-plus-circle {
      font-size: 3rem;
      font-weight: normal;
    }
    span {
      font-weight: 600;
    }
  }
  .deleteAddBox {
    background-color: @error-color;
    color: #fff;
    padding: 8px;
    border-radius: 50%;
    float: right;
  }
}
