@media only screen and (min-width: 1000px) {
    #mobile_body {
        background: #555;
        background-image: url('../../assets/images/linen.png');
    }
    #mobile_body #mobile_page {
        -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 40%);
        -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
        -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
        box-shadow: 0 0 15px rgb(0 0 0 / 40%);
        margin: 50px auto;
        width: 320px;
        padding: 10px;
    }
}

#mobile_body .campaign-condition {
    padding: 15px;
}

#mobile_body .navbar-brand {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 100px;
    margin-bottom: 15px;
}

#mobile_body .navbar-brand img {
    width: 100%;
    height: 100%;
    position: relative;
}

.barcode svg {
    width: 100%;
    text-align: center;
    margin: 0px auto;
}

.campaign-condition h3 {
    font-size: 13px;
}

.campaign-condition p {
    font-size: 10px;
}

.text-center svg{
    width: 100% !important;
}