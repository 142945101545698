@import "~antd/lib/style/color/colorPalette.less";
@import "~antd/dist/antd.less";
@import "~antd/lib/style/themes/default.less";

// These are shared variables that can be extracted to their own file
@primary-color: #425ee2;
@border-radius-base: 4px;
@primary-color-hover: #5e76e5;
@sidebar-menu-light: #f3f3ff;
@sidebar-menu-dark: #192e5a;
@menu-text-light: #9ba8aa;
@orange-color: #fb984a;
@aqua-color: #3bd8a8;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-layout-header {
  background: #fff !important;
}

.ant-breadcrumb {
  a {
    &:hover {
      color: @primary-color!important;
    }
  }
}
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgba(139, 140, 243, 0.2) !important;
  border-color: @primary-color!important;
  &:hover,
  &:fucus {
    box-shadow: 0 0 0 2px rgba(139, 140, 243, 0.2) !important;
    border-color: @primary-color!important;
  }
}
.search-input:not(.ant-input-affix-wrapper-disabled) {
  &:hover {
    border-color: @primary-color!important;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(139, 140, 243, 0.2) !important;
    border-color: @primary-color!important;
  }
}
.ant-btn-primary {
  background: @primary-color!important;
  border-color: @primary-color!important;
  &:hover {
    background: @primary-color-hover!important;
    border-color: @primary-color!important;
    color: #fff !important;
  }
}
.ant-modal-footer {
  .ant-btn {
    &:hover {
      border-color: @primary-color!important;
      color: @primary-color!important;
    }
  }
  .ant-btn.ant-btn-primary {
    &:hover {
      border-color: @primary-color!important;
      color: #fff !important;
    }
  }
}
.ant-menu-item,
.ant-menu-submenu-title {
  &:active {
    background-color: @sidebar-menu-light!important;
  }
}
.ant-input {
  &:hover {
    border-color: @primary-color!important;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(139, 140, 243, 0.2) !important;
    border-color: @primary-color!important;
  }
}
#productEdit .ant-tooltip.ant-tooltip-placement-bottom {
  display: none;
}
