.campaignDetail {
    background-color: #fff;
    .left {
      .campaignBox {
        background-color: #f1f2f9;
        padding: 20px;
        border-radius: @border-radius-box;
        margin-bottom: 20px;
        .nameBox {
          h2 {
            color: #001847;
            margin-bottom: 20px;
            font-weight: 600;
          }
          .statusBox {
            padding: 5px 16px;
            border-radius: 4px;
            color: #fff;
            display: inline-block;  
            text-align: center;
            &.Draft {
              background-color: @heading-light-color;
            }
            &.Active {
              background-color: @aqua-color;
            }
            &.Published {
              background-color: @orange-color;
            }
            &.Expired {
              background-color: @text-danger;
            }
          }
        }
        .createdDateBox {
          .codeBox {
            width: 255px;
            border-radius: @border-radius-box;
            justify-content: center;
            align-items: center;
            padding: 8px 26px;
            margin-right: 30px;
            font-weight: 600;
            color: @sidebar-menu-dark;
            border: 2px dashed @heading-light-color;
          }
          .dateBox {
            width: 255px;
            border-radius: @border-radius-box;
            padding: 12px 20px;
            font-weight: 600;
            color: @sidebar-menu-dark;
            border: 2px solid @heading-light-color;
            align-items: center;
            span {
              color: @heading-light-color;
            }
            h4 {
              color: @sidebar-menu-dark;
              font-size: 1rem;
              margin-bottom: 0px;
              font-weight: 600;
            }
          }
        }
        .copyBox {
          width: 100%;
          input {
            padding: 10px 15px;
            background-color: transparent;
            border: 2px solid @heading-light-color;
            color: @heading-light-color;
          }
          button {
            padding: 10px 5px;  
            background-color: @sidebar-menu-dark;
            color: #fff;
            height: 46px;
            width: 40px;
            border: 0px;
          }
        }
      }
      .OfferValueBox {
        display: flex;
        margin-bottom: 20px;
        .innerBox {
          padding: 20px;
          border-radius: @border-radius-box;
          color: #fff;
          font-size: 1.2rem;
          .shadow;
          h5 {
            color: #fff;
            margin-bottom: 0px;
          }
          p {
            margin-bottom: 0px;
          }
          &.denominationBox {
            background-color: @orange-color;
          }
          &.voucherBox {
            background-color: @primary-color;
          }
          &.valueBox {
            background-color: @aqua-color;
          }
        }
      }
      .productsBox {
        padding: 20px;
        border-radius: @border-radius-box;
        border: 1px solid #E5E5E5;
        .heading {
          color: @sidebar-menu-dark;
          margin-bottom: 20px;
        }
        .shadow;
        .innerBox {
          border-radius: 4px;
          margin-bottom: 20px;
          overflow: hidden;
          .shadow-sm;
          img {
            width: 100%;
            height: 150px;
            object-fit: contain;
            border-radius: 4px 4px 0 0;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            &:hover{
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }
          }
          .details {
            padding: 10px;
            color: @sidebar-menu-dark;
            p {
              margin-bottom: 0px;
              font-weight: 500;
              span {
                color: @primary-color;
              }
            }
          }
        }
      }
    }
    .right {
      .dateBox {
        border-radius: @border-radius-box;
        border: 1px solid #E5E5E5;
        margin-top: 12px;
        padding: 20px;
        display: flex;
        .shadow-sm;
        & > span {
          padding: 8px 14px;
          border-radius: @border-radius-box;
          margin-right: 15px;
          font-size: 1.2rem;
          display: flex;
          align-items: center;
          .shadow-sm;
          &.startIcon {
            background-color: @aqua-light-color;
            color: @aqua-color;
          }
          &.endIcon {
            background-color: @bg-color-error-light;
            color: @text-danger;
          }
        }
        p {
          margin-bottom: 0px;
          span {
            font-size: 0.8rem;
            color: @heading-light-color;
          }
          h4 {
            font-weight: 600;
            margin-bottom: 0px;
            color: @heading-light-color;
          }
        }
      }
      .stateBox {
        padding: 20px;
        border-radius: @border-radius-box;
        margin-bottom: 20px;
        .heading {
          display: block;
          font-weight: 600;
          margin-bottom: 15px;
          color: @sidebar-menu-dark;
        }
        .shadow;
        .inner {
          flex-wrap: wrap !important;
          display: flex;
          span {
            background-color: @primary-color;
            color: #fff;
            padding: 4px 18px;
            margin-right: 4px;
            margin-bottom: 4px;
            border-radius: 20px;
            font-size: 0.75rem;
          }
        }
      }
      .templateBox {
        display: flex;
        .qrCodeBox {
          padding: 20px 20px 4px 20px;
          border-radius: @border-radius-box;
          .shadow;
          img {
            background-color: @box-gray-color;
            padding: 10px;
            border-radius: @border-radius-box;
            width: 100%;
            height: 148px;
            object-fit: contain;
          }
          .downloadIcon {
            color: @aqua-color;
            font-size: 1.2rem;
            display: flex;
            justify-content: flex-end;
            padding: 5px 0;
            cursor: pointer;
            left: 14px;
            position: relative;
          }
        }
        .previewBox {
          padding: 20px;
          border-radius: @border-radius-box;
          .shadow;
          .heading{
            font-weight: 600;
            margin-bottom: 5px;
            color: @sidebar-menu-dark;
          }
          img {
            width: 100%;
            height: 138px;
            object-fit: contain;
            border:2px dashed #E5E5E5;
            border-radius: @border-radius-box;
            padding: 8px;
          }
        }
      }
    }
  }
  