.productList {
  .productListCard {
    border-radius: 20px;
    overflow: hidden;
    .ant-card-body {
      padding: 0px;
      .cardHeader {
        position: relative;
        border-radius: 20px;
        height: 286px;
        img {
          width: 100%;
          height: 200px;
          object-fit: contain;
          border-radius: 20px 20px 0 0;
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transition: .3s ease-in-out;
          transition: .3s ease-in-out;
        }
        &:hover img{
          -webkit-transform: scale(1.3);
	        transform: scale(1.3); 
        }
        .editBox {
          background: @aqua-color;
          padding: 2px 7px;
          color: #fff;
          font-size: 1.2rem;
          border-radius: 50%;
          position: absolute;
          right: 10px;
          bottom: 85px;
          z-index: 10;
        }
      }
      .cardFooter {
        text-align: center;
        padding: 15px  5px;
        bottom: -1px;
        overflow: visible;
        position: absolute;
        width: 100%;
        background: #fff;
        border-radius: 20px;
        h4 {
          font-weight: 700;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 44px;
        }
        p {
          font-weight: 500;
          margin-bottom: 0px;
          span {
            color: @orange-color;
            font-weight: 700;
          }
        }
        .separator {
          color: @bg-color-light-gray!important;
          margin: 0px 6px;
        }
      }      
    }
  }
}
.ant-empty{
  height: 400px;
}