#Dashboard {
  @import "../../../assets/css/common.less";
  .dashboard-left-box {
    border-radius: 6px;
    padding-bottom: 24px;
    .card-header {
      // background-image: url("../../../assets/images/Template/bg-pink.jpg");
      // background-position: center;
      // background-size: cover;
      background-color: @primary-color!important;
      color: #fff;
      padding: 24px 28px 113px 28px;
      border-radius: 6px;
      h2 {
        color: #fff;
        font-size: 3rem;
        line-height: 2.5rem;
        margin-bottom: 0rem;
      }
      h3 {
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
      small {
        font-weight: 700;
      }
      .stock-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.4rem;
        .country-icon {
          background: #fff;
          padding: 3px;
          border-radius: 3px;
          .shadow;
        }
      }
    }
    .card-body {
      background-color: #fff;
      margin-top: -100px !important;
      width: 85%;
      margin: auto;
      padding: 14px;
      border-radius: 6px;
      height: 322px;
      overflow-y: scroll;
      .shadow-sm;
      &::-webkit-scrollbar {
        width: 0.5em;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        margin: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(211, 211, 211);
        border-radius: 20px;
        margin: 5px;
      }
      .products-stock-table {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        border-radius: 6px;
        &:hover {
          background-color: @bg-row-highlightcolor;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        .product-icon {
          margin-right: 10px;
          display: flex;
          span {
            border: 1px solid @border-color-base;
            padding: 10px;
            border-radius: 6px;
            font-weight: 700;
            color: #666;
            font-size: 1rem;
          }
          img {
            width: 45px;
            height: 47px;
            object-fit: contain;
            border: 1px solid @border-color-base;
            padding: 6px;
          }
        }
        .product-name {
          h4 {
            margin-bottom: 0px;
          }
        }
        .count {
          margin-left: auto;
          h4 {
            margin-bottom: 0px;
            font-weight: 700;
            font-size: .9rem;
            display: flex;
            align-items: center;
            .anticon-arrow-up {
              color: green;
            }
            .anticon-arrow-down {
              color: red;
            }
          }
        }
      }
    }
  }
  /*Map*/
  #mapBox {
    g[aria-labelledby="id-79-title"] {
      display: none !important;
    }
  }
  /*Products Selected*/
  .products-selected-wrapper {
    .box {
      background-color: #fff;
      border-radius: 4px;
      padding: 20px 25px;
      margin-bottom: 14px;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      .inner{
        border-left:3px solid @orange-color;
        padding: 0 10px;
        margin-bottom: 10px;
      }
      h4 {
        font-size: 1rem;
        margin-bottom: 0px;
        font-weight: 600;
        .text-ellipse1;
      }
      p {
        margin-bottom: 0px;
        font-size: 0.75rem;
        color: #333;
        background: @bg-color-ligher-gray;
        display: inline-flex;
        padding: 4px 14px;
        border-radius: 2px;
        font-weight: 600;
      }
      .anticon-shop {
        border-radius: 0.475rem;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 5px;
        color: #333;
      }
      .product-count-box {
        border-radius: 20px;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;
        h2 {
          margin-bottom: 0px;
          font-size: 0.8rem;
          background-color: @aqua-color;
          border-radius: 2px;
          color: #fff;
          font-weight: 600;
          padding: 2px 14px;
        }
        h3 {
          margin-bottom: 0px !important;
          font-weight: 500 !important;
          font-size: 0.85rem;
          color: #000;
        }
      }
      // .card-bottom {
      //   height: 1px;
      //   background-color: @primary-color;
      //   width: 20%;
      //   position: absolute;
      //   bottom: 25px;
      //   left: 50%;
      //   -webkit-transform: translateX(-50%) translateY(-50%);
      //   -moz-transform: translateX(-50%) translateY(-50%);
      //   transform: translateX(-50%) translateY(-50%);
      // }
      // &:hover .card-bottom {
      //   height: 1px;
      //   background-color: @primary-color;
      //   width: 80%;
      //   position: absolute;
      //   bottom: 25px;
      //   left: 50%;
      //   -webkit-transform: translateX(-50%) translateY(-50%);
      //   -moz-transform: translateX(-50%) translateY(-50%);
      //   transform: translateX(-50%) translateY(-50%);
      //   transition-timing-function: ease-in;
      //   transition: 0.5s;
      // }
    }
  }

  /*Products List*/
  .store-product-list-box {
    border-radius: 6px;
    padding-bottom: 14px;
    .card-body {
      background-color: #fff;
      margin: auto;
      padding: 14px;
      border-radius: 6px;
      height: 478px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0.5em;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        margin: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(211, 211, 211);
        border-radius: 20px;
        margin: 5px;
      }
      .products-stock-table {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        border-radius: 6px;
        &:hover {
          background-color: @bg-row-highlightcolor;
        }
        &:last-child {
          margin-bottom: 0px;
        }
        .product-icon {
          margin-right: 10px;
          display: flex;
          span {
            border: 1px solid @border-color-base;
            padding: 10px;
            border-radius: 6px;
            font-weight: 700;
            color: #666;
            font-size: 1rem;
          }
          img {
            width: 45px;
            height: 47px;
            object-fit: contain;
            border: 1px solid @border-color-base;
            padding: 6px;
            border-radius: 6px;
          }
        }
        .product-name {
          h4 {
            margin-bottom: 0px;
          }
          small {
            color: #a5a5a5;
          }
        }
        .count {
          margin-left: auto;
          h4 {
            margin-bottom: 0px;
            font-weight: 700;
            font-size: .9rem;
            display: flex;
            align-items: center;
            .anticon-arrow-up {
              color: green;
            }
            .anticon-arrow-down {
              color: red;
            }
          }
        }
      }
    }
  }

  /*Store Information*/
  .store-information-box {
    border-radius: 6px;
    padding-bottom: 18px;
    .card-header {
      // background-image: url("../../../assets/images/Template/bg-pink.jpg");
      // background-position: center;
      // background-size: cover;
      background-color: @primary-color!important;
      color: #fff;
      padding: 24px 28px 80px 28px;
      border-radius: 6px;
      h2 {
        color: #fff;
        font-size: 1.2rem;
        line-height: 2.5rem;
        margin-bottom: 0rem;
      }
      h3 {
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
      small {
        font-weight: 700;
      }
      .stock-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
        .store-icon {
          background: #fff;
          padding: 5px 15px;
          border-radius: 3px;
          color: #666;
          font-size: 2rem;
          .shadow;
        }
      }
    }
    .card-body {
      margin-top: -65px !important;
      width: 85%;
      margin: auto;
      .count-box {
        background-color: #fff;
        border-radius: 6px;
        padding: 14px;
        text-align: center;
        margin-bottom: 15px;
        .shadow-sm;
        p {
          font-weight: 600;
          margin-top: 5px;
          margin-bottom: 0px;
        }
        h3 {
          font-size: 1.2rem;
          font-weight: 700;
          margin-bottom: 0px;
        }
        .anticon.anticon-fund {
          background: @text-danger;
          color: @bg-danger;
          padding: 10px 12px;
          border-radius: 6px;
          font-size: 1.6rem;
          .shadow-sm;
        }
        .anticon.anticon-shopping {
          background: @text-warning;
          color: @bg-warning;
          padding: 10px 12px;
          border-radius: 6px;
          font-size: 1.6rem;
          .shadow-sm;
        }
      }
      .ant-image {
        background: #fff;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ddd;
        .shadow-sm;
        margin-bottom: 5px;
        width: 100%;
        .ant-image-img {
          height: 60px;
          object-fit: cover;
        }
      }
    }
  }
}

@hack: true;@import "/var/www/html/new_brand/src/theme/light-theme.less";