.contact-banner-section {
    padding-top: 3rem !important;
    padding-bottom: 6rem !important;
    background: linear-gradient(#dbdefa, #fff);
    h1 {
        padding-top: 6rem !important;
        font-size: 3.4rem;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 5rem;
        color: @f-blue-color;
        margin-bottom: 0.6rem!important;
    }
    h5 {
        font-weight: 500;
        font-size: 1.1rem;
    }
    .contact-section{
        background-color: #fff;
        padding:4rem  3rem;
        border-radius: 10px;
        .br-10{
            border-radius: 10px;
        }
        .address-section{
            border: 1px solid #ddd;
            border-radius: 10px;
            padding: 2rem!Important;
            strong{
                font-weight: 600;
            }
            h4{
                font-size: 1.4rem;
                font-weight: 600;
            }
            img{
                height: 40px;
                width: 50px;
                object-fit: cover;
            }
            .anticon{
                color:@f-secondary-color;
                font-size: 1.4rem;
            }
        }
        .contact-form{
            input{
                padding: 11px 11px;
                border-radius: 25px;
                border-color:#DBDEFA!important;
                border: 2px solid #ddd;
            }
            textarea{
                padding: 11px 11px;
                border-radius: 10px;
                border-color:#DBDEFA!important;
                border: 2px solid #ddd;
            }
            .btn-section{
                .ant-btn{
                    background-color: #FF8911!important;
                    border-color:#FF8911!important;
                    border-radius: 50px;
                    padding: 1.3rem 3.2rem;
                    line-height: 0rem;
                }
                .ant-form-item-control-input-content{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
}