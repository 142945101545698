.campaignGrid{
    border-radius: 10px;
    .ant-card-head{
        border-radius: 10px 10px 0 0;
        .ant-card-head-title{
            font-weight: 900;
            font-size: 1.1rem;
        }
    }
    .ant-card-body{
        background: rgb(250, 250, 250);
        padding: 3px;
        border-radius: 0 0 10px 10px;
        & > div{
            background: #fff;
            padding: 20px;
            border-radius: 8px;
            .shadow-sm;
            .dateBox{
                display: flex;
                flex-direction: row;
                border-left: 5px solid;
                padding: 10px;
                margin-bottom: 20px;
                p{
                    margin-bottom: 0px;
                    color: #999;
                }
                h3{
                    margin-bottom: 0px;
                    font-size: 0.95rem;
                }
                .startDate{
                    padding-right: 30px;
                    border-right: 1px solid #333;
                }
                .endDate{
                    padding-left: 30px;
                }
            }
            .stateBox{
                display: flex;
                flex-direction: column;
                padding: 10px;
                border-radius: 6px;
                .shadow-sm;
                h4{
                    color:#394459;
                    margin-bottom: 10px;
                }
                p{
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 0px;
                    span{
                        border: 1px solid #ADADAD;
                        padding: 4px 16px;
                        border-radius: 30px;
                        margin-right: 10px;
                        margin-bottom: 6px;
                        color:#000;
                    }
                }
                
            }
            .countBox{
                background: #F6F6F6;
                padding: 10px;
                border-radius: 6px;
                margin-bottom: 20px;
                line-height: 1.4rem;
                h2{
                    margin-bottom: 0px;
                    font-weight: 900;
                }
                h4{
                    margin-bottom: 0px;
                    font-size: .8rem;
                }
            }
            .offBox{
                padding: 10px;
                border-radius: 6px;
                text-align: center;
                h2{
                    color: #fff;
                    margin-bottom: 0px;
                }
            }
        }
    }
}