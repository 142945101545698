@import url("./common.less");
/*2. Common CSS*/
.page-body,
.ant-layout {
  background: #fff !important;
  overflow-x: hidden;
}

.error {
  color: var(--red-color);
  font-size: 13px;
}

.f-primary {
  color: @f-primary-color;
}
.f-secondary {
  color: @f-secondary-color;
}
.f-ternary {
  color: @f-ternary-color;
}

.textd-none {
  text-decoration: none;
}

.fs-1 {
  font-size: 1rem;
}

.br-10 {
  border-radius: 10px;
}

.br-width-25 {
  width: 25px;
  height: 1px;
  background-color: var(--gray-color);
}
.countryPhoneCode {
  position: absolute;
  left: 10px;
  top: 32px;
}
.cursor-pointer {
  cursor: pointer;
}
.h-200 {
  height: 200px;
}
.overflowYscroll {
  overflow-y: scroll;
}
#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}
#style-1::-webkit-scrollbar-track-piece {
  width: 12px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.list-type-none {
  list-style-type: none !important;
}
.spaceY {
  padding: 4rem 0;
}
.heading {
  color: #fff;
  display: inline-flex;
  font-size: 1rem;
  padding: 0.7rem 1.6rem;
  border-radius: 0px 10px 10px 0px;
  margin-bottom: 1rem;
  &.primary {
    background-color: @f-primary-color;
  }
  &.secondary {
    background-color: @f-secondary-color;
  }
  &.ternary {
    background-color: @f-ternary-color;
  }
}
/*2.Common CSS*/

/*3.Custom Button*/

.btn-join {
  font-size: 18px !important;
  padding: 6px 40px;
}

.btn-join span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.btn-join span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: -4px;
  right: -20px;
  transition: 0.5s;
  font-size: 22px;
}

.btn-join:hover span {
  padding-right: 25px;
}

.btn-join:hover span:after {
  opacity: 1;
  right: 0;
}

/*3.Custom Button*/

@media screen and (min-width: 1900px) {
}
@media screen and (min-width: 1680px) {
}

@media screen and (min-width: 1600px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1386px) {
}

@media (min-width: 768px) and (max-width: 1280px) {
}

/*13. Responsive Section*/

/*Small devices (landscape phones, 576px and up)*/

@media only screen and (max-width: 600px) {
  header .logo {
    width: 150px !important;
  }
  header .navbar-toggler {
    background-color: var(--black-color);
    padding: 0px 4px;
    margin-right: 18px;
  }
  header .header-navbar li.nav-item {
    width: 100%;
    background: #fff;
    padding: 5px 10px;
  }
  .login-btn-section {
    width: 95%;
    background: #fff;
    padding: 5px 10px;
    margin-left: 0px !important;
  }
  .login-btn-section a.btn {
    color: var(--black-color);
    padding: 0px 0 0 0px;
    position: relative;
    text-transform: uppercase;
    font-size: 1rem !important;
  }
  header .header-navbar {
    margin-bottom: 0px !important;
    width: 95%;
    margin-left: 0px !important;
  }
  header .header-navbar li.nav-item a.nav-link {
    padding-top: 0px;
  }
  .about-section .h-100.d-flex.text-left.px-5 {
    padding: 0px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  header .navbar-toggler {
    background-color: var(--black-color);
    padding: 4px 4px;
    margin-right: 0px;
  }
  header .header-navbar li.nav-item {
    width: 100%;
    background: #fff;
    padding: 5px 10px;
  }
  .login-btn-section {
    width: 100%;
    background: #fff;
    padding: 5px 10px;
    margin-left: 0px !important;
  }
  .login-btn-section a.btn {
    color: var(--black-color);
    padding: 0px 0 0 0px;
    position: relative;
    text-transform: uppercase;
    font-size: 1rem !important;
  }
  header .header-navbar {
    margin-bottom: 0px !important;
    width: 100%;
    margin-left: 0px !important;
  }
  header .header-navbar li.nav-item a.nav-link {
    padding-top: 0px;
  }
  .about-section .h-100.d-flex.text-left.px-5 {
    padding: 0px !important;
  }
}
