.cTemplateSec{
    .cTemplatePreview{
        display: flex;
        justify-content: center;
        position: relative;
        img{
            position: relative;
            width: 400px;
            height: 400px;
            object-fit: contain;
            padding: 10px;
            border: 2px dashed #ddd;
            margin-bottom: 5px;
        }
        .box{
            text-align: center;
            button{
                border:0px;
                font-weight: 600;    
                padding:10px 20px;
                cursor: move;           
            }
        }
        .cTemplateButtonSec{
            margin-left: 20px;
            border: 0px;
            .btnFooter{
                text-align: center;
                padding-top: 10px;
                button:first-child{
                    background-color: @aqua-color!important;
                    border-color: @aqua-color!important;
                }                
            }
        }
    }
}
.templateUploadSec{
    padding: 5rem!important;
    border: 2px solid #eee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    .ant-upload-list-picture-card-container{
        display: flex;
        justify-content: center;
        margin: auto;
    }
    .ant-upload.ant-upload-select.ant-upload-select-picture-card{
        width: 100%;
        height: 200px;
        margin: auto;
        border: 2px dashed #425EE2!important;
        .dragDropText{
            color: #425EE2;
            span{
                font-size: 3rem;
            }
        }
    }
}