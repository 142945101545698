.campaignAddBox {
  input.hidden {
    position: absolute;
    visibility: hidden;
  }
  input[type="radio"] ~ .showWhenChecked {
    display: none;
  }
  input[type="radio"] + label {
    margin-bottom: 20px;
  }
  input[type="radio"]:checked ~ .showWhenChecked {
    display: block;
    margin-bottom: 20px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  input[type="radio"]:checked + label {
    margin-bottom: 0px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  label.step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    width: 100%;
    background: #fff;
    cursor: pointer;
    color: @sidebar-menu-dark;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .arrowIcon {
      color: #fff;
      border-radius: 50%;
      padding: 4px 8px;
      cursor: pointer;
      &.arrow1 {
        background-color: @primary-color;
      }
      &.arrow2 {
        background-color: @orange-color;
      }
      &.arrow3 {
        background-color: @aqua-color;
      }
      &.arrow4 {
        background-color: @text-info;
      }
    }
  }
  input[type="radio"]:checked + label .arrowIcon {
    transform: rotate(90deg);
  }
  .btnNext {
    float: right !important;
    .anticon.anticon-right {
      font-size: 0.7rem;
    }
  }
}
