.notificationView{
    .innerBox{
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #ddd;
        padding: 20px;
        header{
            display: flex;
            justify-content: space-between;
            h2{
                font-size: 1.6rem;
            }
        }
        .notificationBox main{
            max-height: 500px!important;
        }
    }
}