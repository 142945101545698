.campaignAddBox {
    .campaignInformationCard {
        .denominationText {
          background: #ebebf6;
          padding: 10px;
          border-radius: 8px;
          .ant-input-affix-wrapper {
            height: 34px;
            margin-bottom: 6px;
            border: 0px;
          }
          label {
            font-weight: 500;
            color: @sidebar-menu-dark;
          }
          .ant-input {
            border: 0px;
          }
          .ant-input-number-input{
            height: 38px;
          }
        }
        .noOfVoucherText {
          background: #e4e9e0;
          padding: 10px;
          border-radius: 8px;
          label {
            font-weight: 500;
            color: @sidebar-menu-dark;
          }
          .ant-input {
            border: 0px;
            height: 34px;
            margin-bottom: 6px;
          }
          .ant-input-number-input{
            height: 38px;
          }
        }
        .offerValueBox {
          background-color: @aqua-color;
          padding: 19px 10px;
          border-radius: 8px;
          color: #fff;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            margin-bottom: 0px;
          }
          h4 {
            color: #fff;
            font-size: 1.25rem;
            margin-bottom: 0px;
          }
        }
        .ant-form-item {
          label {
            font-weight: 500;
          }
          .ant-input {
            padding: 8px 11px;
          }
          .ant-picker {
            width: 100%;
            padding: 8px 11px;
          }
          .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 40px;
            padding: 4px 11px;
          }
         
        }
        .ant-upload-list-picture-card .ant-upload-list-item-info::before{
          left: 0px!important;
        }
        &.profileSec{
          border-radius: 10px;
          .ant-card-body{
            padding: 48px;
            .brandLogo{
              width:90px;
              height:90px;
              background: #ddd;
              border-radius: 50%;
              flex: none;
              img{
                object-fit:contain;
                width: 100%;
                padding:5px;
                height: 100%;
                border-radius: 50%
              }
            }
            h2{
              color: #80878F;
              font-size: 1.6rem;
            }
            .copyBox {
              width: 100%;
              input {
                padding: 10px 15px;
                background-color: transparent;
                border: 2px solid;
                border-color:  @sidebar-menu-dark!important;
                color: @heading-light-color;
              }
              button {
                padding: 10px 5px;  
                background-color: @sidebar-menu-dark;
                color: #fff;
                height: 46px;
                width: 40px;
                border: 0px;
              }
            }
            .ant-input {
              padding: 10px 11px!important;
              border-color: #f5f5f5!important;
              color: #999;
            }
            .ant-select-selector {
              height: 44px!important;
              border-color: #f5f5f5!important;
              padding: 8px 11px!important;
              color: #999;
            }
            .ant-input-group-addon{
              border-color: #f5f5f5!important;
              background-color: #f5f5f5!important;
              color: #999;
            }
          }
          
        }
      }
}