.campaignHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 6px -6px #666;
  margin-bottom: 25px;
  padding: 0 39px;
  height: 68px;
  .codeBox {
    width: 255px;
    background-color: #f4f6f3;
    border-radius: @border-radius-box;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 8px 26px;
    margin-right: 30px;
    font-weight: 600;
    color: @sidebar-menu-dark;
  }
  .copyBox {
    width: 60%;
    margin-right: 30px;
    input {
      padding: 7px 15px;
      background-color: @box-gray-color;
      border: 0px;
      color: @menu-text-light;
    }
    button {
      padding: 7px 5px;
      background-color: @orange-color;
      color: #fff;
      height: 36px;
      width: 40px;
      border: 0px;
    }
  }
  .downloadBox {
    margin-right: 20px;
    margin-left: auto;
    width: 40px;
    span {
      cursor: pointer;
      color: @aqua-color;
      font-size: 1.8rem;
    }
  }
  .qrBox {
    box-shadow: 0px 2px 12px -6px #666;
    padding: 4px;
    border-radius: 4px;
    margin-left: auto;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    span {
      font-size: 1.8rem;
      background-color: #fff;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        position: relative;
      }
    }
  }
}
