.banner-section{
    .b-icon{
        position: absolute;
        right: 0px;
        width: 200px;
        bottom: 341px;
    }
}
.brand-solution-section{
    h3{
        line-height: 2.4rem;
    }
}
.digital-section1{
    background-color: @f-gray-color;
    margin-top: -150px;
    .content{
        background-color: #fff;
        border-radius: 10px;
        min-height: 300px;
        .checked-icon{
            font-size: 4rem;
            display: flex;
            justify-content: center;
            margin-top: -56px;
            padding-bottom: 2rem;
            .anticon{
                color: @f-ternary-color !important;
            }
        }
    }
    h3{
        line-height: 2.4rem;
    }
}
.digital-section2{
    h2 {
        line-height: 3.6rem;
        font-size: 2.6rem;
        font-weight: 600;
    }
}
.curve-top{
    img{
        width: 100%;
        margin-top: -236px;
    }
}
.curve-bottom{
    img{
        width: 100%;
        margin-top: -236px;
    }
}

