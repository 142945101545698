.campaignListCard {
  .campaignBox {
    background-color: @box-gray-color;
    padding: 0 15px 15px 15px;
    border-radius: @border-radius-box;
    .ant-table {
      background: transparent;
      table {
        thead {
          th {
            background-color: @box-gray-color !important;
            color: @sidebar-menu-dark;
            border: 0px;
            padding: 24px 6px 6px 6px;
            &::before {
              background-color: transparent;
            }
            &.ant-table-cell-scrollbar{
              display: none;
            }
          }
        }
      }
      .ant-table-body {
        padding-right: 8px;
        &::-webkit-scrollbar {
          width: 8px;
          background-color: #f5f5f5;
        }
         
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          background-color: #f5f5f5;
        }
         
        &::-webkit-scrollbar-thumb {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #acacac;
        }
        table {
          border-collapse: separate;
          border-spacing: 0px 8px;
        }
      }
      tbody {
        td {
          background-color: #fff;
          border-top: 1px solid #e6e6e6;
          border-bottom: 1px solid #e6e6e6;
          padding: 8px 8px;
          img {
            height: 45px;
            width: 45px;
            object-fit: cover;
            border-radius: @border-radius-box;
          }
          .productNameValue {
            font-weight: 500;
            color: @sidebar-menu-dark;
          }
          .createdBox{
            span{
              font-size: .75rem;
            }
          }
          .stateDateBox,.endDateBox{
            font-size: .8rem;
          }
          &:first-child {
            border-radius: 6px 0px 0px 6px;
            border-left: 1px solid #e6e6e6;
            font-weight: 500;
          }
          &:last-child {
            border-radius: 0px 6px 6px 0px;
            border-right: 1px solid #e6e6e6;
            text-align: center;
          }
          .totalOfferValueText {
            color: @aqua-color;
            font-weight: 600;
          }
          .statusBox {
            padding: 4px 12px;
            width: 100px;
            border-radius: 4px;
            color: #fff;
            display: block;
            text-align: center;
            &.Draft {
              background-color: @heading-light-color;
            }
            &.Active {
              background-color: @aqua-color;
            }
            &.Published {
              background-color: @orange-color;
            }
            &.Expired {
              background-color: @text-danger;
            }
          }
          .viewBtn{
            a{
              color: @heading-light-color;
            }
          }
        }
      }
    }
  }
}
.ant-empty{
  height: 400px;
}